import { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import { GOOGLE_LOGIN } from "constants/loginConstants";
import { useSession } from "next-auth/react";
import { GenerateUniqId } from "utils/deviceIdGenerator";
import * as actions from "../../redux/actions/authActions";
import OneTapGoogleSignIn from "components/auth/oneTapGoogleSignIn";
import Header from "../shared/header";
import AmpHeader from "../shared/header/ampHeader";
import AmpFooter from "../shared/footer/AmpFooter";

const Footer = dynamic(() => import("../shared/footer"));
const PopupModal = dynamic(() => import("../shared/modal/popupModal"));
const Loader = dynamic(() => import("../shared/loader/transparentLoader"));
// const Header = dynamic(() => import("../shared/header"));
// const AmpHeader = dynamic(() => import("../shared/header/ampHeader"));
// const AmpFooter = dynamic(() => import("../shared/footer/AmpFooter"));

const Layout = ({ Component, pageProps }) => {
  const router = useRouter();
  const { data: session } = useSession();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [splashLoader, setSplashLoader] = useState(false);

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setLoading(true);
      setSplashLoader(true);
      dispatch({ type: "ROUTE_CHANGED", payload: true });
      // destory all ad slots
      if (window && typeof window.googletag !== "undefined") {
        const { googletag } = window;
        googletag.cmd.push(function () {
          googletag.destroySlots();
          // googletag.defineOutOfPageSlot(
          //   '/1007232/Lallantop_Mweb_Interstitial',
          //   googletag.enums.OutOfPageFormat.INTERSTITIAL
          //   ).addService(googletag.pubads());
        });
      }
      if (window && typeof window._taboola !== "undefined") {
        window._taboola = window._taboola || [];
        _taboola.push({ flush: true });
      }
    });
    router.events.on("routeChangeComplete", () => {
      setLoading(false);
      setSplashLoader(false);
      dispatch({ type: "ROUTE_CHANGED", payload: false });
    });
  }, [router, dispatch]);

  setTimeout(() => setSplashLoader(false), 1000);

  const responseGoogle = useCallback(() => {
    // if(router?.pathname?.includes("/login")){
      GenerateUniqId().then((deviceId) => {
        console.log("inside reponse");
  
        const deviceToken = localStorage.getItem("deviceToken");
  
        let loginObj = {
          loginType: GOOGLE_LOGIN,
          deviceType: "web",
          deviceId,
          deviceToken,
          facebookId: process.env.NEXT_PUBLIC_FACEBOOK_ID,
          googleId: session?.user?.uniqueId,
          appleId: "",
          fullname: session?.user?.name,
          email: session?.user?.email,
          GoogleToken: session?.id_token,
        };
        dispatch(actions.LoggedIn(loginObj));
      });
    // }

  }, [
    dispatch,
    session?.user?.email,
    session?.user?.uniqueId,
    session?.user?.name,
  ]);

  console.log("current session =>", session);

  useEffect(() => {
    console.log("session or responseGoogle changed", session);
    const data = JSON.parse(localStorage.getItem("lallantopSession2"))
    console.log("LLT SESSION 2",data)
    if (session?.user && !data?.id) {
      console.log("session or responseGoogle changed");
      responseGoogle();
    }
  }, []);

  return (
    <>
      <PopupModal />
      {loading && <Loader />}
      <OneTapGoogleSignIn />

      <div
        style={{
          display: splashLoader && router.pathname === "/" ? "" : "none",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <img
          src="https://static.thelallantop.com/images/post/1690876464482_frame.webp?width=200"
          width="170px"
          height="170px"
          loading="lazy"
          alt="The Lallantop"
        />
      </div>

      <div
        style={{
          opacity: splashLoader && router.pathname == "/" ? 0 : 1,
          pointerEvents: splashLoader && router.pathname == "/" ? "none" : "",
        }}
      >
        {!router.pathname.includes("/jwplayer") &&
          !router.pathname.includes("/amp") &&
          !router.pathname.includes("lallantop-adda/terms-and-conditions") && (
            <Header />
          )}
        {router.pathname.includes("/amp") && <AmpHeader />}

        <Component {...pageProps} />

        {!router.pathname.includes("/jwplayer") &&
          !router.pathname.includes("/amp") &&
          !router.pathname.includes("lallantop-adda/terms-and-conditions") && (
            <Footer />
          )}
        {router.pathname.includes("/amp") && <AmpFooter />}
      </div>
    </>
  );
};

export default Layout;
