import { SessionProvider } from "next-auth/react";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

import Layout from "components/layout";
import i8intl from "localization/i8intl";
import { overRideDefaultConsole } from "logging/log-util";
import { wrapper } from "store";
import { setCookie } from "utils/cookieHelper";
import appCheck from "utils/firebaseAppCheck";

import "../styles/globals.scss";
import { MasterConfigContext } from "constants/MasterConfigContext";

// import { Partytown } from '@builder.io/partytown/react';

const FacebookPixelNoScript = dynamic(
  () => import("../constants/NoScriptTags/FaceBookPixel"),
  { ssr: false }
);
const ScorecardResearchNoScript = dynamic(
  () => import("../constants/NoScriptTags/ScorecardResearch"),
  { ssr: false }
);

function MyApp({Component, pageProps,masterConfigData}) {
  const [lang, setLang] = useState(i8intl.defaultLocale);
  const [messages, setMessages] = useState(i8intl.defaultMessages);
  const [firstScroll, setFirstScroll] = useState(false);
  const [showMukta, setShowMukta] = useState(false);
  const {session} = pageProps || {};
  // update messages
  useEffect(() => {
    const locale = i8intl.getLanguage();
    if (locale) setMessages(i8intl.allMessages[locale]);
    if (locale !== lang) setLang(locale);
  }, [lang]);
  overRideDefaultConsole();
  async function getTokenForAPI() {
    let appCheckTokenResponse;
    try {
      appCheckTokenResponse = await getToken(
        appCheck,
        /* forceRefresh= */ true
      );
    } catch (err) {
      console.error(err);
      // Handle any errors if the token was not retrieved.
      return;
    }
    return appCheckTokenResponse;
  }

  useEffect(async () => {
    let token = await getTokenForAPI();
    console.log("This is toekn ", token);
    setCookie("appCheckToken", token?.token);
  }, []);

  useEffect(() => {
    async function onFirstScroll() {
      // const firebaseApp = (await import("utils/firebase")).default;
      // const { getMessaging, getToken } = await import("firebase/messaging");
      // const messaging = getMessaging(firebaseApp);
      // getToken(messaging).then((token) =>
      //   localStorage.setItem("deviceToken", token)
      // );
      setShowMukta(true);
    }

    function handleScroll() {
      onFirstScroll();
      window.removeEventListener("scroll", handleScroll);
    }

    window.addEventListener("scroll", handleScroll);
  }, []);

  const reverseProxy = function (url, location, type) {
    console.log("url", url);
    if (type === "script") {
      let hostname = location.origin;
      const urls = [
        "https://www.googletagmanager.com",
        "http://www.googletagmanager.com",
        "https://www.google-analytics.com",
        "https://www.googletagservices.com",
        "https://connect.facebook.net",
        "http://ads.pubmatic.com",
        "http://www.google-analytics.com",
        "https://sb.scorecardresearch.com/",
        "https://securepubads.g.doubleclick.net",
        "http://securepubads.g.doubleclick.net",
      ];
      const result = urls.some((item) => url.href.startsWith(item));
      if (result) {
        let finalURL = `${url.href}`;
        var proxyUrl = new URL(`${hostname}/api/proxy`);
        proxyUrl.searchParams.append("href", finalURL);
        console.log("proxy", proxyUrl);
        return proxyUrl;
      }
    }
    return url;
  };

  useEffect(() => {
    var showAds = true;

    if (process.env.NEXT_PUBLIC_ADS_ONSCROLL === "true" && firstScroll) {
      showAds = false;
    }
    if (showAds) {
      console.log("showAds app.js");
      async function onFirstScroll() {
        //Amazon-ads
        !(function (a9, a, p, s, t, A, g) {
          if (a[a9]) return;
          function q(c, r) {
            a[a9]._Q.push([c, r]);
          }
          a[a9] = {
            init: function () {
              q("i", arguments);
            },
            fetchBids: function () {
              q("f", arguments);
            },
            setDisplayBids: function () {},
            targetingKeys: function () {
              return [];
            },
            _Q: [],
          };
          A = p.createElement(s);
          A.async = !0;
          A.src = t;
          g = p.getElementsByTagName(s)[0];
          g.parentNode.insertBefore(A, g);
        })(
          "apstag",
          window,
          document,
          "script",
          "//c.amazon-adsystem.com/aax2/apstag.js"
        );
        setFirstScroll(true);

        // const gpt = document.createElement("script");
        // gpt.setAttribute(
        //   "src",
        //   "https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        // );
        // gpt.setAttribute("async", "true");
        // document.head.appendChild(gpt);

        // window.dataLayer = window.dataLayer || [];
        // gtag("js", new Date());
        // gtag("config", "UA-203175993-6");

        // window.googletag = window.googletag || { cmd: [] };

        // window.dataLayer = window.dataLayer || [];
        // function gtag() {
        //   dataLayer.push(arguments);
        // }
        // gtag("js", new Date());
        // gtag("config", "G-RXRPKR4310");

        // const gtag1 = document.createElement("script");
        // gtag1.setAttribute(
        //   "src",
        //   "https://www.googletagmanager.com/gtag/js?id=UA-203175993-6"
        // );
        // gtag1.setAttribute("async", "true");
        // document.head.appendChild(gtag1);
        // const gtag2 = document.createElement("script");
        // gtag2.setAttribute(
        //   "src",
        //   "https://www.googletagmanager.com/gtag/js?id=G-RXRPKR4310"
        // );
        // gtag2.setAttribute("async", "true");
        // document.head.appendChild(gtag2);

        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", "565755245197146");
        fbq("track", "PageView");

        window._izq = window._izq || [];
        window._izq.push(["init"]);

        const izooto = document.createElement("script");
        izooto.setAttribute(
          "src",
          "https://cdn.izooto.com/scripts/1c4c6ae9b71ca79df0cb41d12468c28c30c79e5f.js"
        );
        izooto.setAttribute("async", "true");
        document.head.appendChild(izooto);

        const gsiClient = document.createElement("script");
        gsiClient.setAttribute("src", "https://accounts.google.com/gsi/client");
        gsiClient.setAttribute("async", "true");
        document.head.appendChild(gsiClient);
      }
      onFirstScroll();

      function handleScroll() {
        // onFirstScroll();
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("touchstart", handleScroll);
        window.removeEventListener("click", handleScroll);
      }

      window.addEventListener("scroll", handleScroll);
      window.addEventListener("touchstart", handleScroll);
      window.addEventListener("click", handleScroll);
    }
  }, [firstScroll]);

  // useEffect(()=>{
  //   (function (w, d, s, l, i) {
  //     w[l] = w[l] || [];
  //     w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l != "dataLayer" ? "&l=" + l : "";
  //     j.async = true;
  //     j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  //     f.parentNode.insertBefore(j, f);
  //   })(window, document, "script", "dataLayer", "GTM-P9LRV6T");
  // },[])
  // const reverseProxy = function (url, location, type) {
  //   // console.log("initial url", url);
  //   if (type === "script") {
  //     let hostname = location.origin;
  //     const urls = [
  //       "https://www.googletagmanager.com",
  //       "http://www.googletagmanager.com",
  //       "https://www.googletagservices.com",
  //       "https://connect.facebook.net",
  //       "http://ads.pubmatic.com",
  //       "https://securepubads.g.doubleclick.net",
  //       "http://securepubads.g.doubleclick.net",
  //       "https://www.google-analytics.com",
  //       "https://www.google-analytics.com/analytics.js",
  //       "https://sb.scorecardresearch.com",
  //     ];
  //     const result = urls.some((item) => url.href.startsWith(item));
  //     if (result) {
  //       let finalURL = `${url.href}`;
  //       var proxyUrl = new URL(`${hostname}/api/proxy`);
  //       proxyUrl.searchParams.append("href", finalURL);
  //       console.log("proxy", proxyUrl);
  //       return proxyUrl;
  //     }
  //   }
  //   if(url.href.includes('analytics')){
  //     let tmp=new URL(`${location.origin}/api/proxy?href=https://www.google-analytics.com/analytics.js`);
  //     return tmp
  //   }
  //   return url;
  // };

  return (
    <>
      <Head>
        {showMukta === true && (
          <link rel="preload" as="font" href="https://fonts.googleapis.com" />
        )}
        {showMukta === true && (
          <link
            rel="preload"
            as="font"
            href="https://fonts.gstatic.com"
            crossOrigin
          />
        )}
        {showMukta === true && (
          <link
            as="font"
            href="https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&display=swap"
            rel="stylesheet"
          />
        )}
         <script
              id="gpt"
              type="text/javascript"
              async
              src={`https://securepubads.g.doubleclick.net/tag/js/gpt.js`}
            />
        {/* <Partytown
      forward={["dataLayer.push"]}
      resolveUrl={reverseProxy}
      /> */}
        <script
          id="GTMScript"
          type="text/javascript"
          // type="text/partytown"
          // strategy="worker"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-P9LRV6T');
  `,
          }}
        />
        {/* <script async type="text/javascript" id="interactive_js_adcode" src="https://1437953666.rsc.cdn77.org/publisher/cad1fe69-6f15-11ee-afad-fb9218c3e151/truereachAdRender.js"></script>
          
 
  
           <script async type="text/javascript" id="interactive_js_adcode" src="https://1437953666.rsc.cdn77.org/publisher/cad1fe69-6f15-11ee-afad-fb9218c3e151/truereachAdRender.js"></script>
           */}
        {/* <Script
            id="GTMScript"
            // strategy="worker"
            strategy="afterInteractive"
            
          /> */}
      </Head>

      <IntlProvider
        messages={messages}
        locale={lang}
        defaultLocale={i8intl.defaultLocale}
      >
        <SessionProvider session={session} refetchInterval={60 * 60 * 24}>
          {/* Global Layout */}
          <MasterConfigContext.Provider value={masterConfigData}>
          <Layout Component={Component} pageProps={pageProps} />
          </MasterConfigContext.Provider>
          {/* ---- Thirdparty Scripts for global use case ---- */}

          {/* <Script
          id="amazon-ads"
          type="text/javascript"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");
                `,
          }}
        ></Script> */}
          <meta
            name="bingbot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />

          {/* {firstScroll && (
          <Script
            id="newrelic"
            strategy="lazyOnload"
            type="text/javascript"
            src={`/js/${process.env.NEXT_PUBLIC_NEW_RELIC_JS}`}
          />
          
        )} */}
          {firstScroll && (
            <Script
              id="ga"
              strategy="lazyOnload"
              type="text/javascript"
              src={`https://www.google-analytics.com/analytics.js`}
            />
          )}
          {/* {firstScroll && ( */}
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-RXRPKR4310"
          ></Script>
          {/* )} */}
          {/* {firstScroll && ( */}
          <Script
            id="GTAGScript"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `  window.dataLayer = window.dataLayer || [];   function gtag() {     window.dataLayer.push(arguments);   }  gtag('js', new Date());   gtag('config', 'G-RXRPKR4310'); `,
            }}
          ></Script>

          {/* Clarity */}
            <Script
            id="ClarityScript"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "g7z9ar87id");`,
            }}
          ></Script>
          <Script
            id="comscore"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `    var _comscore = _comscore || [];
              _comscore.push({
                c1: "2",
                c2: "8549097",
                options: {
                  enableFirstPartyCookie: true
                },
              });
              (function () {
                var s = document.createElement("script"),
                  el = document.getElementsByTagName("script")[0];
                s.async = true;
                s.src = "https://sb.scorecardresearch.com/cs/8549097/beacon.js";
                el.parentNode.insertBefore(s, el);
              })(); `,
            }}
          ></Script>
          {/* )} */}
          {/* <script async type="text/javascript" id="interactive_js_adcode" src="https://1437953666.rsc.cdn77.org/publisher/cad1fe69-6f15-11ee-afad-fb9218c3e151/truereachAdRender.js"></script><div id="TR-15a0897c-6f16-11ee-afad-03a642d2a1ef"></div> */}

          {/* <Script
          id="gsi-client"
          src="https://accounts.google.com/gsi/client"
          strategy="lazyOnload"
        /> */}
          {firstScroll && (
            <noscript>
              <ScorecardResearchNoScript />
            </noscript>
          )}
          {firstScroll && (
            <noscript>
              <FacebookPixelNoScript />
            </noscript>
          )}
          {/* <div id="TR-dcb83fdf-6f16-11ee-afad-0ff8d7fbba54"></div>
           <div id="TR-15a0897c-6f16-11ee-afad-03a642d2a1ef"></div> */}
          <noscript>
            <img src="http://sb.scorecardresearch.com/p?c1=2&c2=8549097&cv=4.4.0&cj=1" />
          </noscript>
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-P9LRV6T"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        </SessionProvider>
      </IntlProvider>
    </>
  );
}

MyApp.getInitialProps = async (appCtx) => {

  try {
    // Check if running on server
    // let isServer = !!appCtx.ctx.req;
    let masterConfigData = null;
    // if (isServer) {
    //   console.log("App.js running on the server");
    //   // const { getMenu } = await import("@/handlers/menu");
    //   // menu = await getMenu();
    //   const masterConfig = await fetch(
    //     `https://static.thelallantop.com/json-configs/public/file.json`
    //   ).then(async (res) => {
    //     return await res.json();
    //   });
    //   masterConfigData = masterConfig?.data;
    //   console.log("master data ",masterConfigData)
    // } else {
    //   console.log("Running on the client");
    // }
    console.log("App.js running on the server");
    // const { getMenu } = await import("@/handlers/menu");
    // menu = await getMenu();
    const masterConfig = await fetch(
      `https://static.thelallantop.com/json-configs/public/file.json`
    ).then(async (res) => {
      return await res.json();
    });
    masterConfigData = masterConfig?.data;
    console.log("master data ",masterConfigData)

    return {
      masterConfigData,
      session:null
    };
  } catch (error) {
    console.error(error);
    return {
      masterConfigData: {},
      session:null
    };
  }
};


export default wrapper.withRedux(MyApp);
